<template>
  <div class="fileinput text-center">
    <div
      class="fileinput-new thumbnail"
      v-if="src !== false"
      :class="{ 'img-circle': type === 'avatar' }"
    >
      <img :src="image" alt="preview" :style="previewStyle" />
    </div>
    <div>
      <span class="btn btn-primary btn-simple btn-file">
        <span class="fileinput-new">{{
          fileExists || showDeleteOnSrc ? changeText : selectText
        }}</span>
        <input type="hidden" value="" name="" />
        <input
          accept="image/*"
          @change="handlePreview"
          type="file"
          name="..."
          class="valid"
          :multiple="false"
          aria-invalid="false"
        />
      </span>
      <base-button
        v-if="showDeleteButton && (fileExists || showDeleteOnSrc)"
        @click="removeFile"
        round
        type="danger"
      >
        <i class="fas fa-times"></i> {{ removeText }}
      </base-button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'image-upload',
  props: {
    type: {
      type: String,
      default: '',
      description: 'Image upload type (""|avatar)'
    },
    src: {
      type: String || Boolean,
      default: '',
      description: 'Initial image to display'
    },
    showDeleteOnSrc: {
      type: Boolean,
      default: false,
      description: 'Show edit/delete button on initial image stage'
    },
    selectText: {
      type: String,
      default: 'Select image'
    },
    changeText: {
      type: String,
      default: 'Change'
    },
    removeText: {
      type: String,
      default: 'Remove'
    },
    showDeleteButton: {
      type: Boolean,
      default: true
    },
    previewStyle: {
      default: ''
    }
  },
  data () {
    const avatarPlaceholder = '/img/placeholder.jpg'
    const imgPlaceholder = '/img/image_placeholder.jpg'
    return {
      placeholder: this.type === 'avatar' ? avatarPlaceholder : imgPlaceholder,
      imagePreview: null
    }
  },
  computed: {
    fileExists () {
      return this.imagePreview !== null
    },
    image () {
      return this.imagePreview || this.src || this.placeholder
    }
  },
  methods: {
    handlePreview (event) {
      const file = event.target.files[0]
      if (this.src !== false) {
        this.imagePreview = URL.createObjectURL(file)
      }

      this.$emit('change', file)
    },
    removeFile () {
      this.imagePreview = null
      this.$emit('change', null)
    }
  }
}
</script>
<style></style>
